import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Point from "./BulletPoint";
import Grid from '@mui/material/Grid';

export default function About() {
  return (
    <Box sx={{ flexGrow: 1 }}>

        <Typography variant="h4" style={{fontFamily: "typewriter", color: "#34a4eb"}} component="div" sx={{ flexGrow: 1 }}>
            Reasons to Choose Us
          </Typography>

        <Grid container direction="column" justify="flex-start" align="center" >
            <Point text="BACE is your best solution for meeting municipal requests for residential and commercial Civil Engineering projects."></Point>
            <Point text="Public Works Encroachment plans- All design and utilities that extend beyond your property line will require an encroachment permit with your local municipality."></Point>
            <Point text="BACE will provide AC pavement analysis for your parking lot maintenance project."></Point>
            <Point text="BACE is a Qualified Storm Water Pollution Prevention Plan(SWPPP) Developer (QSD). We can meet the needs of your SWPPP site requirements with the State of California."></Point>
            <Point text="Utility planning and design. BACE is familiar on how to meet municipal standards for permitting."></Point>
            <Point text="Drainage design to meet Bay Area storm water standards for small and medium size projects."></Point>
            <Point text="American with Disabilities Act (ADA) knowledge to meet current grading standards."></Point>
            <Point text="Site grading to meet Bay Area municipality standards."></Point>
        </Grid>
    </Box>
  );
}
