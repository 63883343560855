import logo from './logo.svg';
import './App.css';
import NavBar from "./components/NavBar.js";
import About from "./components/About.js";
import BulletPoints from "./components/BulletPoints.js";
import Credentials from "./components/Credentials.js";
import Mission from "./components/Mission.js";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import Form from "./components/Form.js";
import { useState, useContext, createContext } from "react";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import gearImg from "./gears.png";
import landing from "./landing.png";
import residential from "./Residential Home Pool Patio Grading Drainage.jpeg"
import waterdownhill from "./WaterFlowsDownhill.jpeg";

export const FormContext = createContext(); 

function App() {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="App">
      <NavBar />
      

      <div style={{padding: "40px", margin: "50px 50px 0px 50px"}}>

        <img src={gearImg}  style={{width: "20vw", height:"100%", margin: "20px"}}/>
        <About />

        <img src={waterdownhill} style={{width: "50vw", height:"100%"}}/>


        <hr />
        <br />
        {/* <img src={homeImg} /> */}
        <Mission />

        {/* <hr /> */}
        <br />

        <img src={residential} style={{width: "40vw", height:"100%", margin: "20px", borderRadius: "10px"}}/>
        <BulletPoints />

        <hr />
        <br />
        <Credentials />
      </div>

      <FormContext.Provider value={{open, setOpen}}>
      <Button onClick={handleToggle} variant="outlined" style={{fontFamily: "typewriter", margin: "50px"}}>Send us a message!</Button>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <Form open={open} />
      </Backdrop>
      </FormContext.Provider>
  
    </div>
  );
}

export default App;
