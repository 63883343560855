import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function Mission() {
    return (
      <Box sx = {{flexGrow: 1}} >
        <Paper elevation={8} style={{backgroundColor: "#0b68a3", padding: "30px"}}>
        <Typography variant = "h4" style = {{fontFamily: "typewriter", color: "#34a4eb"}} component = "div" sx = { { flexGrow: 1} }> About </Typography>

        <br />

        <Typography variant = "h6" style = {{fontFamily: "typewriter", color: "white", margin: "20px"}} component = "div" sx = { { flexGrow: 1} }> Bay Area Civil Engineers(BACE) focus is to provide you with design expertise services on your small to medium size Civil Engineering projects. </Typography>

        <Typography variant = "h6" style = {{fontFamily: "typewriter", color: "white",  margin: "20px"}} component = "div" sx = { { flexGrow: 1} }>  BACE has been serving the Bay Area since 1998. BACE has over 30 years experience in designing residential projects and commercial design.</Typography>

        <Typography variant = "h6" style = {{fontFamily: "typewriter", color: "white",  margin: "20px"}} component = "div" sx = { { flexGrow: 1} }> Our experience serving Bay Area is your best choice for project value. Licensed Civil Engineers in the State of California. Localized in the Bay Area. </Typography>

        </Paper>

        </Box>
    );
}