import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function About() {
  return (
    <Box sx={{ flexGrow: 1 }}>

        <Typography variant="h4" style={{fontFamily: "typewriter", color: "#34a4eb"}} component="div" sx={{ flexGrow: 1 }}>
            Expertise and Experience
          </Typography>

          <br />

        <Typography variant="h6" style={{fontFamily: "typewriter", margin: "20px"}} component="div" sx={{ flexGrow: 1 }}>
          BACE brings more than 30 years of experience in civil engineering design. 
          BACE has a diverse background with public and private high tech commercial development projects, residential grading projects, school facility designs, roadway design with ADA requirements and local jurisdiction needs.
        </Typography>

        <Typography variant="h6" style={{fontFamily: "typewriter", margin: "20px"}} component="div" sx={{ flexGrow: 1 }}>
          Responsibilities include project planning, conceptual design, hydraulic studies, parking lot designs, grading and improvement design, plans and specification preparation.
        </Typography>

        <Typography variant="h6" style={{fontFamily: "typewriter", margin: "20px"}} component="div" sx={{ flexGrow: 1 }}>
          BACE is a qualified SWPPP Developer (QSD). BACE is familiar with all Bay Area regional stormwater requirements.
        </Typography>

    </Box>
  );
}
