import React, { useState, useContext } from "react";
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { FormContext } from "../App.js";

import { send } from 'emailjs-com';

const defaultValues = {
    name: "",
    email: "",
    phoneNumber: "",
    projectDescription: "",
  };
  const Form = (onClick) => {
    const { open, setOpen } = useContext(FormContext);
    // const [open, setOpen] = useState(props.open);

    const [form, setForm] = useState({
      name: '',
      project: '',
      email: '',
      phone_number: ''
    });

  
    const handleChange = (e) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value,
      });
    };

    const handleClose = () => {
      setOpen(false);
    }

    const onSubmit = (e) => {
      e.preventDefault();
      send(
        'service_pxk0owd',
        'template_ykz8t29',
        form,
        'user_OEd7L0KxbvtyC8Lt6BPdQ'
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert("Email successfully sent!")
          setOpen(false);
        })
        .catch((err) => {
          console.log('FAILED...', err);
          alert("Error sending message!");
          setOpen(false);
        });
    };

    return (
      <form id="form">
        <Grid container style={{ fontFamily: "typewriter", backgroundColor: "white", width: "60vw", padding: "20px"}} alignItems="center" justify="center" direction="column">

          <IconButton onClick={handleClose} color="primary" style={{position: "relative", left: "22vw"}}>
            <ClearIcon />
          </IconButton>

          <Grid item style={{color: "#34a4eb", fontSize: "20px"}}>Contact Us!</Grid>
          <Grid item style={{margin: "20px"}}>
            <TextField
              id="name"
              name="name"
              label="Name"
              type="text"
              variant="standard"
              placeholder='from name'
              value={form.name}
              onChange={handleChange}

              value={form.name}
              onChange={handleInputChange}
              inputProps={{style: {fontFamily: "typewriter", width: "40vw"}}} // font size of input text
              InputLabelProps={{style: {fontFamily: "typewriter"}}} // font size of input label
            
            />
          </Grid>
          <Grid item style={{margin: "20px"}}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="text"
              variant="standard"
              value={form.email}
              onChange={handleInputChange}
              inputProps={{style: {fontFamily: "typewriter", width: "40vw"}}} // font size of input text
              InputLabelProps={{style: {fontFamily: "typewriter"}}} // font size of input label
            
            />
          </Grid>
          <Grid item style={{margin: "20px"}}>
            <TextField
              id="phoneNumber"
              name="phone_number"
              label="Phone Number"
              type="text"
              variant="standard"
              value={form.phone_number}
              onChange={handleInputChange}
              inputProps={{style: {fontFamily: "typewriter", width: "40vw"}}} // font size of input text
              InputLabelProps={{style: {fontFamily: "typewriter"}}} // font size of input label
            
            />
          </Grid>

          <Grid item style={{margin: "20px"}}>
            <TextField
                id="description"
                label="Project Description"
                name="project"
                multiline
                maxRows={10}
                minCols={10}
                variant="standard"
                value={form.project}
                onChange={handleInputChange}
                inputProps={{style: {fontFamily: "typewriter", width: "40vw"}}} // font size of input text
                InputLabelProps={{style: {fontFamily: "typewriter"}}} // font size of input label
            />

          </Grid>

          
          
          <Button onClick={onSubmit} variant="contained" color="primary" type="submit" style={{fontFamily: "typewriter"}}>
            Send
          </Button>

        </Grid>
      </form>
    );
  };
  export default Form;
