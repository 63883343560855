import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export default function BulletPoint(props) {
  return (
    <Grid item xs={6} style={{margin: "20px"}}>
        <Paper elevation={10}>
            <Typography variant="h6" style={{fontFamily: "typewriter", padding:"20px"}} component="div" sx={{ flexGrow: 1 }}>
                {props.text}
            </Typography>
        </Paper>
    </Grid>
  );
}
