import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function Credentials() {
  return (
    <Box sx={{ flexGrow: 1 }}>

        <Typography variant="h4" style={{color: "#34a4eb", fontFamily: "typewriter"}} component="div" sx={{ flexGrow: 1 }}>
            Credentials
          </Typography>
          <br />

        <Typography  variant="h6" style={{fontFamily: "typewriter"}} component="div" sx={{ flexGrow: 1 }}>
            <i>Education:</i> B.S. Civil Engineering, Cal Poly State University
        </Typography>
        <Typography  variant="h6" style={{fontFamily: "typewriter"}} component="div" sx={{ flexGrow: 1 }}>
            <i>Licensure:</i> California Professional Civil Engineer, Qualified SWPPP Developer
        </Typography>

    </Box>
  );
}
